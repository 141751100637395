import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import { FaFileContract, FaPencilRuler } from 'react-icons/fa'
import { MdBuild } from 'react-icons/md';
import { StaticQuery, graphql } from "gatsby"

let Benefit = ({Icon = MdBuild, title, content}) => (
  <div className="d-flex mb-4">
    <Icon size={30} className="text-primary"/>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

let Benefits = ({data}) => (
  <Container className="py-5">
    <Row className="d-flex align-items-center justify-content-center">
      <Col md="6">
        <div className="mb-4">
          <h2 className="text-primary">Uw interieur op maat in 3 stappen</h2>
        </div>
        <Benefit title="Our Design" Icon={FaPencilRuler} content="Samen bespreken we uw wensen. Hier maken wij een voorbeeld design van." />
        <Benefit title="Offerte" Icon={FaFileContract} content="Zitten we op één lijn? Dan maken wij de offerte op" />
        <Benefit title="Bouwen" content="We plannen en regelen de levering van het materiaal en gaan aan de slag!" />
      </Col>
    </Row>
  </Container>
)

export default () => (
  <StaticQuery
    query={graphql`
      query BenefitsQuery {
        file(relativePath: {eq: "sample.png"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Benefits data={data}/>
    )}
  />
)
