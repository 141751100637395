import React from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby"
import styled, { keyframes } from "styled-components"

import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';

const SliderContainer = styled.div`
  background: rgba(7, 45, 42, 0.5);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 15rem 0 10rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
`;

const RDesign = keyframes`
  0%, 18% {
    content: "R";
  }
   
  20% {
    content: "r";
  }
  23% {
    content: "ur";
  }
  26%, 48% {
    content: "Our";
  }
  
  
  50% {
    content: "our";
  }
  52% {
    content: "ur";
  }
  54% {
    content: "r";
  }
  56% {
    content: "R";
  }
  58% {
    content: "Ra";
  }
  60% {
    content: "Ras";
  }
  62% {
    content: "Rasi";
  }
  64% {
    content: "Rasid";
  }
  66%, 88% {
    content: "Rasidi";
  }
  
  90% {
    content: "Rasid";
  }
  92% {
    content: "Rasi";
  }
  94% {
    content: "Ras";
  }
  96% {
    content: "Ra";
  }
  98%, 100% {
    "content: "R";
  }
`;

const R = styled.div`
  display: inline-block;
  content: "our";
  &:after {
    content: "R";
    animation: ${RDesign} 10s linear infinite;
  }
 
`;

export default () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const sideMetadata = useStaticQuery(graphql`
    query TitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  let {title} = sideMetadata.site.siteMetadata
  return (
    <SliderContainer>
      <Slider {...settings}>
        <div>
          <div className="text-center">
            <h1><R/>&nbsp;-&nbsp;Design</h1>
          </div>
        </div>
        <div>
          <div className="text-center">
            <h1>{title}</h1>
          </div>
        </div>
        <div>
          <div className="text-center">
            <h1>{title}</h1>
          </div>
        </div>
      </Slider>
    </SliderContainer>
  )
}
